import React, { Dispatch, useEffect } from "react";
import { Footer } from "./components/common/Footer";
import Header from "./components/common/Header";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import "./styles/reset.css";
import "./styles/AppNew.css";
import "./styles/FancyHome.css";
import lodash from "lodash";
import { useState } from "react";
import snowStorm from "./helpers/snowfall";
import { SnowOverlay } from "react-snow-overlay";
import ConditionalSnowOverlay from "./components/ConditionalSnowOverlay";

export const ThemeContext = React.createContext({
  theme: "",
  setTheme: null as unknown as Dispatch<React.SetStateAction<string>>,
});

const App = (): JSX.Element => {
  const [theme, setTheme] = useState("default");

  useEffect(() => {
    const scrollEvent = (ev: Event) => {
      let top = Math.round(window.scrollY / 3);
      let [background] = document.getElementsByClassName(
        "scrimbly"
      ) as HTMLCollectionOf<HTMLElement>;

      let [landingPage] = document.getElementsByClassName(
        "landingPage"
      ) as HTMLCollectionOf<HTMLElement>;

      if (!background || !landingPage) return;
      background.style.backgroundPositionY = `${top}px`;
      landingPage.style.backgroundPositionY = `${top * 2}px`;
    };

    window.addEventListener("scroll", lodash.throttle(scrollEvent, 20));
  });

  return (
    <>
      <ThemeContext.Provider value={{ theme: theme, setTheme: setTheme }}>
        <div className={"themeContainer " + theme}>
          <BrowserRouter>
            <Header />
            <main>
              <ConditionalSnowOverlay />
              <Routes />
            </main>
            <Footer />
          </BrowserRouter>
        </div>
      </ThemeContext.Provider>
    </>
  );
};

export default React.forwardRef(App);
