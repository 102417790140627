import PocketBase from "pocketbase";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../App";
import { Link } from "react-router-dom";
import ProfilePic from "../img/profilepic.png";

const Blog = () => {
  const context = useContext(ThemeContext);
  const [blogPosts, setBlogPosts] = useState<any>(null);

  useEffect(() => {
    console.log("new blogposts", blogPosts);
  }, [blogPosts]);
  useEffect(() => {
    const getBlogPosts = async () => {
      const pb = new PocketBase("https://lucashopman.nl/backend");
      const result = await pb.collection("blogposts").getFullList();
      console.log(result);
      setBlogPosts([...result]);
    };
    getBlogPosts();
  }, []);

  return (
    <>
      <section>
        <h1>Blog</h1>
      </section>
      <div className="blogPostsList horizontalCenterFlexbox">
        {" "}
        {blogPosts &&
          blogPosts.map((x: any) => {
            return (
              <Link
                style={{ textDecoration: "none" }}
                to={`/blog/post/${x.id}`}
              >
                <div
                  className="card bigCard noLinkDecoration verticalFlexbox"
                  role="button"
                >
                  <img
                    className="cardImage"
                    src={`https://lucashopman.nl/backend/api/files/blogposts/${x.id}/${x.image}`}
                  />
                  <h3 className="">{x.name}</h3>
                  <div className="horizontalCenterFlexbox gapped pushBottom">
                    <img src={ProfilePic} width={32} height={32} />
                    <em>{new Date(x.created).toLocaleDateString()}</em>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
    </>
  );
};

export default Blog;
