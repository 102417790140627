import { Link } from "react-router-dom";
import Resource from "./Resource";
import Quicksearcher from "./Quicksearcher";
import { useEffect, useRef, useState } from "react";

const Resources = () => {
  const [quickSearcherOpen, setQuickSearcherOpen] = useState(false);
  const sectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    sectionRef.current && !quickSearcherOpen && sectionRef.current.focus();
  }, [sectionRef, quickSearcherOpen]);

  const sources = [
    {
      category: "Cheatsheets",
      websites: [
        { website: "https://tmuxcheatsheet.com/", description: "Tmux" },
        { website: "https://devhints.io/vim", description: "Vim" },
        { website: "https://devhints.io/bash", description: "Bash" },
        { website: "https://dockercheatsheet.com", description: "Docker" },
        {
          website: "https://cheat.sh/",
          description: "Cheat.sh - cheatsheet collection",
        },
      ],
    },
    {
      category: "Web portals",
      websites: [
        { website: "https://entra.microsoft.com", description: "Entra ID" },
        {
          website: "https://admin.microsoft.com",
          description: "Microsoft 365 admin",
        },
        {
          website: "https://github.com",
          description: "Github",
        },
        {
          website: "https://discord.com/developers/applications",
          description: "Discord Dev portal",
        },
      ],
    },
    {
      category: "Backend web",
      websites: [
        {
          website: "https://pocketbase.io/",
          description: "Pocketbase",
        },
      ],
    },
    {
      category: "Frontend web",
      websites: [
        {
          website: "https://www.mingcute.com/",
          description: "Mingcute (nice icons)",
        },
        {
          website: "https://getcssscan.com/css-box-shadow-examples",
          description: "CSS box shadows",
        },
        {
          website: "https://toolkit.addy.codes/",
          description: "Frontend mega toolkit",
        },
      ],
    },
    {
      category: "Tools",
      websites: [
        {
          website: "https://magicstudio.com/magiceraser/",
          description: "Magic Eraser",
        },
        {
          website: "https://monkeytype.com/",
          description: "Monkeytype",
        },
        {
          website: "https://typing.com/",
          description: "Typing.com",
        },
        {
          website: "https://memestudio.lucashopman.nl/",
          description: "Meme Studio",
        },
        {
          website: "https://github.com/ocrmypdf/OCRmyPDF",
          description: "OCR my PDF",
        },
        {
          website: "https://toolbox.googleapps.com/apps/dig/",
          description: "Dig - DNS toolbox",
        },
        { website: "https://mxtoolbox.com/", description: "MXToolbox" },
        {
          website: "https://github.com/ytdl-org/youtube-dl",
          description: "YouTube-dl",
        },
        {
          website: "https://datumprikker.nl/",
          description: "Datumprikker",
        },
        {
          website: "https://haveibeenpwned.com/",
          description: "Have I been pwned",
        },
        {
          website: "https://meyerweb.com/eric/tools/css/reset/",
          description: "reset.css",
        },
        {
          website: "https://pomofocus.io/",
          description: "Pomodoro timer",
        },
        {
          website: "https://photopea.com/",
          description: "Photopea - web based photoshop",
        },
      ],
    },
    {
      category: "References",
      websites: [
        {
          website: "https://github.com/karanpratapsingh/system-design",
          description: "System design",
        },
        {
          website: "https://refactoring.guru",
          description: "Refactoring",
        },
      ],
    },
    {
      category: "Installation Media",
      websites: [
        {
          website: "https://cachyos.org/download/",
          description: "Cachy OS ISO",
        },
        {
          website:
            "https://www.microsoft.com/nl-nl/software-download/windows11",
          description: "Windows 11 ISO",
        },
        {
          website:
            "https://www.microsoft.com/nl-nl/software-download/windows10",
          description: "Windows 10 ISO",
        },
        {
          website: "https://archlinux.org/download/",
          description: "Arch Linux ISO",
        },
        {
          website: "https://ubuntu.com/download/desktop",
          description: "Ubuntu ISO",
        },
      ],
    },
  ];

  return (
    <section
      tabIndex={0}
      ref={sectionRef}
      onKeyDown={(e) => {
        if (e.key == "/") {
          e.preventDefault();
          setQuickSearcherOpen(true);
        }
      }}
    >
      <h1>Resources</h1>
      <p>My personal list of resources for useful webpages</p>
      <p>Press / to quicksearch</p>
      {quickSearcherOpen && (
        <Quicksearcher
          resources={sources}
          setQuickSearcherOpen={setQuickSearcherOpen}
        />
      )}
      {sources.map((x) => (
        <>
          <h3>{x.category}</h3>
          <div className="horizontalCenterFlexbox">
            {x.websites.map((xi) => (
              <Resource website={xi.website} description={xi.description} />
            ))}
          </div>
        </>
      ))}

      {/* <p>My list of resources for useful webpages</p>
      <h3>Cheatsheets</h3>
      <div className="resourceContainer">
        <Resource
          website="https://tmuxcheatsheet.com/"
          description="Tmux (termux)"
        />
        <Resource website="https://devhints.io/vim" description="Vim" />
        <Resource website="https://devhints.io/bash" description="Bash" />
        <Resource
          website="https://dockercheatsheet.com/"
          description="Docker"
        />
      </div>
      <h3>Web portals</h3>
      <div className="resourceContainer">
        <Resource
          website="https://entra.microsoft.com"
          description="Entra ID"
        />
        <Resource
          website="https://admin.microsoft.com"
          description="Microsoft 365 admin"
        />
        <Resource website="https://github.com" description="Github" />
        <Resource
          website="https://discord.com/developers/applications"
          description="Discord Dev portal"
        />
      </div>
      <h3>Backend web</h3>
      <div className="resourceContainer">
        <Resource website="https://pocketbase.io/" description="Pocketbase" />
      </div>
      <h3>Frontend web</h3>
      <div className="resourceContainer">
        <Resource
          website="https://www.mingcute.com"
          description="Mingcute - nice icons"
        />
      </div>
      <h3>Tools</h3>
      <div className="resourceContainer">
        <Resource
          website="https://github.com/ocrmypdf/OCRmyPDF"
          description="OCR my PDF"
        />
        <Resource
          website="https://toolbox.googleapps.com/apps/dig/"
          description="Dig - DNS toolbox"
        />
        <Resource website="https://mxtoolbox.com/" description="MXToolbox" />
        <Resource
          website="https://github.com/ytdl-org/youtube-dl"
          description="YouTube-dl"
        />
        <Resource
          website="https://datumprikker.nl/"
          description="Datumprikker"
        />
      </div>
      <h3>References</h3>
      <div className="resourceContainer">
        <Resource
          website="https://github.com/karanpratapsingh/system-design"
          description="System design"
        />
      </div>
      <h3>Random</h3>
      <div className="resourceContainer">
        <Resource website="https://meow.camera" description="Meow.camera" />
      </div>
    </section> */}
    </section>
  );
};

export default Resources;
