import { useEffect, useState } from "react";

const Quicksearcher = ({ resources, setQuickSearcherOpen }: any) => {
  const [query, setQuery] = useState("");

  useEffect(() => {}, [query, resources]);
  return (
    <div
      className="quickSearcher"
      onKeyDown={(x) => {
        if (x.key == "Escape") {
          setQuickSearcherOpen(false);
        }
        if (x.key == "[") {
        }
      }}
    >
      <input
        autoFocus
        type="text"
        placeholder="Type ] to go to first result"
        onChange={(e) => setQuery(e.target.value)}
        className="searchBox spaced"
      />
      <ul className="noLinkDecoration">
        {resources
          .map((x: any) => x.websites)
          .flat()
          .filter((x: any) => x.description.toLowerCase().includes(query))
          .map((x: any) => {
            return (
              <a href={x.website} target="_blank">
                {" "}
                <li>
                  {" "}
                  <img
                    height="24"
                    width="24"
                    src={`http://www.google.com/s2/favicons?domain=${x.website}`}
                  />
                  {x.description}
                </li>
              </a>
            );
          })}
      </ul>
    </div>
  );
};

export default Quicksearcher;
