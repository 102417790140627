const Resource = ({ website, description }: any) => {
  return (
    <a href={website} target="blank" className="card smallCard">
      <div>
        <img
          height="24"
          width="24"
          src={`http://www.google.com/s2/favicons?domain=${website}`}
        />
        <p>{description}</p>
      </div>
    </a>
  );
};

export default Resource;
