import React from "react";
import MainMenu from "./MainMenu";
import { Link } from "react-router-dom";
import Christmashat from "../../img/christmashat.png";

export const Header = () => {
  return (
    <header className="blurred horizontalCenterFlexbox padded">
      <Link to="/">
        {/* <img src={Christmashat} className="christmasHat" /> */}
        <h3>Lucas Hopman</h3>
      </Link>
      <MainMenu />
    </header>
  );
};

export default Header;
