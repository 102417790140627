import { useEffect, useState } from "react";
import PocketBase from "pocketbase";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

type PostProps = {
  postId: string;
};

const Post = ({ postId }: PostProps) => {
  const [post, setPost] = useState<any>([]);
  let params = useParams() as any;
  useEffect(() => {
    const getBlogPost = async () => {
      const pb = new PocketBase("http://lucashopman.nl/backend");
      const result = await pb.collection("blogposts").getOne(params.id);
      setPost(result);
    };
    getBlogPost();
  }, []);

  return (
    <>
      {post && (
        <>
          <Helmet>
            <meta property="og:title" content={"Blog"} />
            <meta name="description" content="A blog post by lucashopman.nl" />
          </Helmet>
          <div className="blog">
            <h1>{post.name}</h1>
            <img
              src={`http://lucashopman.nl/backend/api/files/blogposts/${post.id}/${post.image}`}
            />
            <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
          </div>
        </>
      )}
    </>
  );
};

export default Post;
