import { SnowOverlay } from "react-snow-overlay";

const ConditionalSnowOverlay = () => {
  if (new Date().getMonth() == 11 && new Date().getDate() > 15) {
    return <SnowOverlay />;
  } else {
    return <></>;
  }
};

export default ConditionalSnowOverlay;
