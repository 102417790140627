import LinkedinSVG from "../common/LinkedinSVG";
import GithubSVG from "./GithubSVG";

export const Footer = () => {
  return (
    <footer className="horizontalCenterFlexbox centered padded">
      <p>
        ©{new Date().getFullYear()}{" "}
        {new Date().getMonth() == 11 &&
          new Date().getDate() > 15 &&
          " - Happy holidays! - "}
      </p>

      <div className="pushRight">
        <a href="https://github.com/LucasDeProgrammeur">
          <GithubSVG width={48} height={48} />
        </a>
        <a href="https://www.linkedin.com/in/lucas-hopman-661256180/">
          <LinkedinSVG width={48} height={48} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
