import React, { useEffect, useRef } from "react";
//const Home = ({ message }: AppProps) => <div>{message}</div>;
import { Link } from "react-router-dom";
import animateOnIntersection from "../helpers/animateOnIntersection";
import GithubSVG from "./common/GithubSVG";

const Home = (): JSX.Element => {
  let animatedItemsRef = useRef([] as Array<HTMLElement>);

  useEffect(() => animateOnIntersection(animatedItemsRef), []);
  return (
    <>
      <section className="landingPage fullscreen">
        <div className="siteTitleContainer">
          <div className="siteTitle">Lucas Hopman</div>
        </div>

        <h3 className="titleSmall">Software dev and administrator</h3>
        <code className={"homeCode centered"}>
          admin@lucashopmansite:~$: echo 'Hello!'
        </code>
        <section>
          <a href="https://github.com/LucasDeProgrammeur/">
            <button className="buttonSecondary">
              <GithubSVG width={15} height={15} />
              Github
            </button>
          </a>
          <Link to="/blog">
            <button className="buttonSecondary">Blog</button>
          </Link>
          <Link to="/Projects">
            <button>Projects</button>
          </Link>
        </section>
      </section>

      <section className="spaced fullscreen">
        <h1>Welcome to my website</h1>
        <p>
          This is the place where I cover various topics that interest me, or
          show off things I've been working on.
        </p>
      </section>
    </>
  );
};
export default React.forwardRef(Home);
