import React, { useState } from "react";
import { Link } from "react-router-dom";
import HamburgerMenuSVG from "./HamburgerMenuSVG";
import LeftArrowBackSVG from "./LeftArrowBack";

export const MainMenu = () => {
  const [visibility, setVisibility] = useState(window.screen.width > 0);

  return (
    <>
      {/* <HamburgerMenuSVG
        visibility={visibility}
        setVisibility={setVisibility}
        width={50}
        height={50}
      /> */}
      <nav className="pushRight">
        <ul className="horizontalCenterFlexbox">
          <Link to="/">
            <li className="padded">Home</li>
          </Link>
          <Link to="/Projects">
            <li className="padded">Projects</li>
          </Link>
          {/* <LeftArrowBackSVG
            width={40}
            height={40}
            visibility={visibility}
            setVisibility={setVisibility}
          /> */}
        </ul>
      </nav>
    </>
  );
};

export default MainMenu;
