import * as React from "react";
import GithubSVG from "../common/GithubSVG";

const ProjectItem = (prop: {
  name: string;
  url: string;
  description: string;
  fromGithub: boolean;
  currentCount: Number;
}) => {
  return (
    <a href={prop.url}>
      <div
        className="card animatedCard"
        style={{
          animation: `projectsAnimation ${
            Number(prop.currentCount) / 3
          }s forwards 0.${Number(prop.currentCount)}s`,
        }}
      >
        <img src={""} alt="" />
        <h3>{prop.name}</h3>
        <p className="projectDesc">{prop.description}</p>
        <GithubSVG height={32} width={32} />
      </div>
    </a>
  );
};

export default ProjectItem;
